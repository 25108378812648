<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :siggys="original_signature_ids.length"
                            :form_data_id="record_id"
                            @getClient="getClient"
                        /><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div class="max800">
                    <!--<div style="text-align:center;"><DynamicImage alt="logo" :urlToRetrieveFile="formLogo ? file.getFile(formLogo) : null" class="company-logo right-20" /></div>-->
                    <p>In order to obtain an Alcohol and Drug Evaluation for the Circuit Court or the Office of the Secretary of State, I agree to provide the following information:</p>
                    <ul>
                        <li>A copy of my driving abstract or a written summary of my driving history obtained from the Office of the Secretary of State;</li>
                        <li>The written results of any chemical testing or documentation of refusal of such testing that occurred after my arrest for driving under the influence of alcohol and/or other drugs (DUI); and</li>
                        <li>Alcohol and drug use history from first use to present.</li>
                    </ul>
                    <p>I also attest to the fact that I have not undergone any other alcohol and drug evaluation as a result of my DUI arrest or if I have, I agree to provide a copy of all such evaluations, if completed and/or the name and address of such program(s). I also give my consent for this program to obtain information from any program(s) where I previously began and/or completed any alcohol and drug evaluation relative to my arrest for DUI. I have read the Department of Human Services brochure entitled “DUI Processes and Evaluations” explaining the alcohol and drug evaluation procedure. I understand that I have the right to withdraw from this evaluation process at any time, refuse the completed alcohol and drug evaluation or seek a second opinion by obtaining another evaluation. I further understand that any information I do provide can be released to the Circuit Court, the Office of the Secretary of State or the Department of Human Services upon request. If I do not complete the evaluation or do not return to sign and obtain my copy of the evaluation within 30 days of its completion date, notice will be sent to the Circuit Court or the Office of the Secretary of State along with any relevant information pertaining to my involvement with this program.</p>
                    <p>Witnessed:</p>
                    <p>IF CONSENT IS NOT GIVEN, PLEASE INDICATE THAT YOU HAVE READ THIS FORM BY INITIALING ON THIS LINE <input v-model="rec.initialName" style="width:20px;" @input="setInitialDate" :disabled="signature_ids.length > 0"></p>
                    <div>Date: {{ rec.initialDate }}</div>
                </div>

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import DynamicImage from '@/components/general/image/Image';
    import { file, settings } from '@/util/apiRequests';
    import { dryalex } from '@/mixins/dryalex'
    import { merge } from 'lodash'

    export default {
        name: 'InformedConsent',
        props: {
            record_id: {
                type: [String, Number],
                required : false,
                default : 0
            },
        },
        components: { FormClient, FormSignature, VIfWorkableModal, DynamicImage, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
                formLogo: null,
                file
            }
        },
        computed: {
            initialDate() {
                return '';
            }
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                //ToDo: Get call from settings to retrieve state department of human services logo via file id.
                /*const res = await this.$api.get(settings.getSetting('state_department_human_services_logo', 'company', 'company'));
                if (res.data) {
                    this.formLogo = res.data.value;
                }*/
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
            setInitialDate() {
                if (this.signature_ids.length <= 0) {
                    if (this.rec.initialName) {
                        let today = new Date();
                        let dd = String(today.getDate()).padStart(2, '0');
                        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                        let yyyy = today.getFullYear();
                        this.rec.initialDate = mm + '/' + dd + '/' + yyyy;
                    } else {
                        this.rec.initialDate = '';
                    }
                }
            }
        },
        async created() {
            await this.init()
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        }
    }
</script>
